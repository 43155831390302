import { useEffect, useState, useMemo } from "react";
import { ref, onValue } from "firebase/database";
import styled from "styled-components"
import "./Content.css"
import { ProGallery } from 'pro-gallery';
import 'pro-gallery/dist/statics/main.css';
import { useResizeDetector } from 'react-resize-detector';


const Content = ({ database }) => {
  const [pictures, setPictures] = useState([])

  useEffect(() => {
    onValue(ref(database, 'pictures'), (snapshot) => {
      const data = snapshot.val();
      setPictures(Array.isArray(data) ? data.map(d => ({
        ...d,
        i: d.src
      })) : Object.values(data).map(d => ({
        ...d,
        i: d.src
      })));
    });
  }, [])
 
  const items = useMemo(() => pictures.map((picture, i) => ({
    itemId: picture.src,
    key: picture.src,
    height: picture.height,
    width: picture.width,
    metaData: {
      type: 'image',
      title: picture.title,
      height: picture.height,
      width: picture.width,
      categories: picture.categories
    },
    meta: picture.meta,
    mediaUrl: picture.src
  }))
    , [pictures]);


  // The options of the gallery (from the playground current state)
  const options = {

    "layoutParams": {
      "structure": {
        "galleryLayout": 1,
        "layoutOrientation": "VERTICAL"
      }
    },
    "behaviourParams": {
      "item": {
        "clickAction": "MAGNIFY"
      }
    }

  };

  const scrollingElement = window;
  const { width, height, ref: containerRef } = useResizeDetector();

  return (<Container ref={containerRef}>
    {width && height ? 
      <ProGallery
        items={items}
        options={options}
        container={{
          width, height: window.innerHeight
        }}
        // eventsListener={eventsListener}
        scrollingElement={scrollingElement}
        customComponents={{
          customHoverRenderer: itemProps => {
            const currentItem = items.find((item) => item.mediaUrl === itemProps.id)
          return <CustomHoverRenderer>
            <h1>{itemProps.title}</h1>
            <h2>Meta:</h2>
            <ul>
              {
                currentItem.meta && Object.entries(currentItem.meta).map(([key, value]) => {
                  return <li>{key}: {value}</li>
                })
              }
              <li></li>
            </ul>
          </CustomHoverRenderer>
          },
        }}
      /> : <></>
}
    </Container>
  );
}

export default Content;


const Container = styled.main`
  width: 100%;
  min-height: 100%;
  line-height: 15px;
`

const CustomHoverRenderer = styled.div`
padding: 2%;
color: white;
  h1 {
    font-size: 16px;
  }

  h2 {
    font-size: 12px;
  }
`