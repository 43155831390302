import React, { Component } from 'react';
import "./Gear.css"

class Gear extends Component {
  render() {
    return (
      <div className={"box"} id='about'>

        <span className={"head"}>ABOUT MY GEAR</span>
        <h2 className={"heading"}>What Am I using?</h2>
        <div className={"About"}>
          <p>
            For my automotive photography, I have found my perfect match in the Fujifilm X-T3 camera. Its incredible value for the money and the vintage look it imparts align perfectly with my passion for both vintage cars and photography. This remarkable camera has become an extension of my artistic vision, allowing me to capture the essence of classic automobiles with a touch of nostalgia.          </p>
          <p className={"br"}>
            One notable aspect of my gear setup is my choice to shoot primarily in JPEG format. Since transitioning to Fujifilm, I have discovered the exceptional image quality and nuanced color rendering that their renowned film simulations provide. The JPEG files straight out of the camera are simply stunning, capturing the vibrancy, richness, and character of each scene in a way that requires minimal post-processing.          </p>
          <p className={"br"}>
            By embracing JPEG shooting, I have streamlined my workflow and embraced a more intuitive and efficient approach to my craft. The Fujifilm X-T3's film simulations, such as Classic Chrome or Acros, bring an added dimension to my images, enhancing the vintage aesthetic that resonates so deeply with me. Each JPEG file is like a perfectly crafted masterpiece, requiring minimal adjustments and allowing me to spend more time focusing on capturing the perfect moment.          </p>
          <p className={"br"}>
            In addition to the camera body, my lens selection plays a crucial role in my photography. I rely on the Fujifilm XF 90mm f/2 lens for its exceptional telephoto capabilities, allowing me to highlight intricate details and bring them to life with stunning clarity. The XF 35mm f/1.4 lens, on the other hand, provides a versatile focal length and beautiful depth of field control, enabling me to capture both wider compositions and intimate close-ups.          </p>
          <p className={"br"}>
            The Fujifilm X-T3 and my lens choices, paired with the decision to shoot in JPEG, have elevated my automotive photography to new heights. The combination of Fujifilm's remarkable image quality, film simulations, and my preferred lenses allows me to create images that truly embody the spirit and character of vintage cars. It's an incredible fusion of technology and artistry that continues to inspire me on my photographic journey.          </p>
          <p className={"br"}>

            Through my portfolio, I invite you to embark on a visual voyage where each photograph, straight out of the camera as a JPEG, encapsulates the beauty and allure of both automobiles and the art of photography. Join me as we celebrate the seamless integration of vintage cars, the Fujifilm X-T3, and the power of JPEG, resulting in images that capture the essence of automotive nostalgia with remarkable precision.  </p>
        </div>
      </div>
    );
  }
}

export default Gear;