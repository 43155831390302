import {useId} from "react"
import Content from "./components/Content";
import Sidebar from "./components/sidebar/Sidebar";
import Gear from "./components/gear/Gear";
import About from "./components/about/About";
import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";
import { getAnalytics, setUserProperties } from "firebase/analytics";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

import "./App.css"

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Get a reference to the database service
export const database = getDatabase(app);

function App() {
  const id = useId();
  setUserProperties(analytics, { id });
  return (
    <Router>
      <div className='App'>
        <div className='side'>
          <nav className='navbar side navbar-expand-lg navbar-light p-0'>
            <button
              className='navbar-toggler'
              type='button'
              data-toggle='collapse'
              data-target='#navbarSupportedContent'
              style={{ zIndex: '1', backgroundColor: "#f2f3f7" }}
            >
              <span className='navbar-toggler-icon'></span>
            </button>
            <div className='collapse navbar-collapse' id='navbarSupportedContent'>
              <Sidebar />
            </div>
          </nav>
        </div>
        <div className='main'>
          <Routes>
            <Route path="/" exact element={<Content database={database}/>} />
            <Route path="/gear" element={<Gear/>} />
            <Route path="/about" element={<About/>} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
