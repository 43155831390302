import React, { Component } from 'react';
import './Sidebar.css';
import { HashLink as Link } from 'react-router-hash-link';

class Sidebar extends Component {
  render() {
    return (
      <div className='sidebar'>
        <div style={{ color: 'black', fontWeight: 'bold' }} className='tagtop'>
          # low_shutter_speed
        </div>
        <div style={{ color: 'black', fontWeight: 'bold' }} className=''>
          # motorsport
        </div>
        <div style={{ color: 'black', fontWeight: 'bold' }} className=''>
          # car_photography
        </div>
        <h1>
          <Link smooth to='/' className='h1_links'>
            Maxime Pocard
          </Link>
        </h1>

        <img src={"https://firebasestorage.googleapis.com/v0/b/photoportfolio-3398a.appspot.com/o/profile_picture.png?alt=media&token=f1d2978d-a1b1-4ae7-96a1-fb94aac59f1f"} />
        <p style={{ color: 'black', fontWeight: 'bold' }} className='gmail'>
          <a
            href='https://mail.google.com/mail/u/0/#inbox?compose=CllgCJftvBffjPmxWxvfHWRHWnhTbpQgPxBxvkcnPpFpFqbhrXMCHXSJTsCXCVLsQzgRcVLpnPL'
            rel='opener noreferrer'
            target='_blank'
            className='fa fa-envelope'
          ></a>{' '}
          pocard.maxime@gmail.com
        </p>

        <ul className='sidebar-nav'>
          <li className='sidebar-nav-items'>
            <Link smooth to='/' className='links'>
              Portfolio
            </Link>
          </li>
          <li className='sidebar-nav-items'>
            <Link smooth to='/about' className='links'>
              About
            </Link>
          </li>
          <li className='sidebar-nav-items'>
            <Link smooth to='/gear' className='links'>
              Gear
            </Link>
          </li>
        </ul>

        <div className='flip-card-back'>
          <ul className='sidebar-nav'>
            <li className='sidebar-nav-icons'>
              <a
                href='https://www.instagram.com/mpocard_photography/'
                rel='opener noreferrer'
                target='_blank'
                className='fa fas fa-instagram fa-lg'
              ></a>
            </li>
            <li className='sidebar-nav-icons'>
              <a
                href='mailto:pocard.maxime@gmail.com'
                rel='opener noreferrer'
                target='_blank'
                className='fa fas fa-envelope fa-lg'
              ></a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Sidebar;