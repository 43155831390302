import React, { Component } from 'react';
import "./About.css"

class About extends Component {
    render() {
      return (
        <div className={"box"} id='about'>
          
            <span className={"head"}>ABOUT ME</span>
            <h2 className={"heading"}>Who Am I?</h2>
            <div className={"About"}>
              <p>
                Welcome to my world of automotive photography! As an amateur car photographer, I combine my lifelong passion for automobiles with the art of capturing their beauty through the lens. My journey began with an intense fascination for cars, where every sleek line, powerful engine, and elegant design would captivate my imagination.
              </p>
              <p className={"br"}>
                Little did I know that my obsession with cars would lead me to discover the incredible world of photography. It started as a hobby, a way to freeze those awe-inspiring moments when a car would ignite my senses. With each click of the shutter, I realized that I could not only preserve the essence of these incredible machines but also convey their unique stories.
              </p>
              <p className={"br"}>
                From the vibrant hues reflecting off a glossy paint job to the intricate details of a finely crafted dashboard, I strive to bring out the soul of every vehicle I photograph. I am constantly seeking the perfect balance of light, composition, and emotion to encapsulate the spirit of each automobile, creating captivating images that transport viewers into the heart of the automotive world.              
              </p>
              <p className={"br"}>
                My photographic journey has been a thrilling adventure of exploration and growth. I have honed my technical skills, studying the interplay between aperture, shutter speed, and ISO to capture cars in their most dynamic and mesmerizing moments. However, it is the emotional connection I establish with my subjects that truly fuels my passion. Behind every photograph lies a story, a narrative that intertwines the machine's history, its owner's love, and my own appreciation for the artistry of automotive design.              
              </p>
              <p className={"br"}>
                Through my portfolio, I invite you to join me on this visual odyssey. Let my photographs take you on a joyride, where you can experience the raw power, the elegance, and the essence of each car. Whether it's a vintage classic, a modern supercar, or an exotic beauty, I aim to ignite your own fascination and appreciation for the automotive world.              
              </p>
              <p className={"br"}>
                Thank you for visiting my photography portfolio. I hope you enjoy exploring my collection as much as I enjoyed creating it. Together, let's celebrate the harmonious marriage of cars and photography, and embark on a journey that transcends mere pixels, revealing the artistry and passion that drive both my subjects and my lens.    
              </p>
            </div>
        </div>
      );
    }
  }
  
  export default About;